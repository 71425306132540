<template>
  <div class="login">
    <div class="login-content">
      <div class="img">
        <img src="@/assets/icon.svg" width="200px" alt="Mr Foody" />
      </div>
      <form @submit.prevent="setAuth">
        <div class="input">
          <a-input size="large" required v-model="email" placeholder="Email" />
        </div>
        <div class="input">
          <a-input-password
            size="large"
            required
            v-model="password"
            placeholder="Password"
          />
        </div>
        <a-button
          class="button"
          size="large"
          @click="setAuth"
          :loading="loadingLogIn"
        >
          Iniciar Sesión
        </a-button>
        <span class="login-error" v-if="loginError"
          >Error al iniciar sesión, verifica <br />tus credenciales y
          reintenta</span
        >
      </form>
    </div>
    <SelectEstablishment
      :visible="selectEstablishmentVisible"
      :establishments="establishments"
      @selectEstablishment="redirectToSelectedEstablishment"
    />
  </div>
</template>

<script>
import "ant-design-vue/dist/antd.css";
import Vue from "vue";
import { Button, Input } from "ant-design-vue";

import SelectEstablishment from "../../components/login/SelectEstablishment.vue";
Vue.use(Button);
Vue.use(Input);
export default {
  name: "LogIn",
  props: {},
  data() {
    return {
      email: "",
      establishments: [],
      password: "",
      loadingLogIn: false,
      loginError: false,
      selectEstablishmentVisible: false,
    };
  },
  components: {
    SelectEstablishment,
  },
  methods: {
    async setAuth() {
      let email = this.email;
      let password = this.password;
      this.loadingLogIn = true;
      try {
        let loginData = await this.$store.dispatch("login", {
          email,
          password,
        });
        if (!loginData.error) {
          this.$store.commit("set_orders_active", []);

          this.establishments = loginData.establishments;
          if (loginData.establishments.length > 1) {
            this.selectEstablishmentVisible = true;
            return;
          }
          this.redirectToSelectedEstablishment(0);
        } else {
          this.loadingLogIn = false;
          this.loginError = true;
        }
      } catch (err) {
        this.loadingLogIn = false;
        this.loginError = true;
        console.log(err);
      }
    },
    async redirectToSelectedEstablishment(establishmentIndex) {
      this.$store.commit("set_establishment", {
        establishment: this.establishments[establishmentIndex],
        index: establishmentIndex,
      });
      let resp = await this.$store.dispatch("get", {
        location: [
          "establishment-menu-by-establishment",
          this.$store.getters.establishmentId,
        ].join("/"),
      });
      this.$store.commit("set_menu", resp.establishment.menus);
      this.loadingLogIn = false;

      this.$router.push("/order-active");
      this.$emit("loggedIn");
    },
  },
};
</script>
