<template>
  <div class="order">
    <div class="order-waiting">
      <div class="logo">
        <img src="@/assets/icon-white.svg" width="150px" alt="Mr Foody" />
      </div>
      <div class="title">
        <p>NUEVA ORDEN</p>
      </div>
      <div class="message">
        <a-button class="btn-confirm" @click="receiveOrder">Ir a órdenes</a-button>
      </div>
      <div class="ignore message">
        <a-button class="" @click="ignoreOrder">Ignorar</a-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "NewOrder",
  methods: {
    ignoreOrder() {
      this.$emit("ignoreOrder");
    },
    receiveOrder() {
      this.$emit("receiveOrder");
    },
  }
};
</script>
