<template>
  <a-modal
    v-model="visible"
    class="select-establishment-modal product-edit-modal"
    title="Selecciona el Establecimiento"
    :maskClosable="false"
  >
    <div class="modal-content">
      <a-row type="flex" class="select-establishment-options">
        <a-select v-model="establishment">
          <a-select-option
            v-for="(establishment, index) in establishments"
            :value="index"
            :key="index"
            >{{ establishment.name }}</a-select-option
          >
        </a-select>
      </a-row>
    </div>
    <template slot="footer">
      <a-button
        key="back"
        @click="$emit('cancel')"
        class="modal-error-btn-close"
      >
        Cerrar
      </a-button>
      <a-button @click="selectEstablishment" class="btn-confirm"> Ok </a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "Error",
  data() {
    return {
      establishment: null,
    };
  },
  props: {
    visible: {
      default: false,
    },
    establishments: null,
    selectedElement: {
      default: 0
    },
  },
  created() {
    this.establishment = parseInt(this.selectedElement);
  },
  methods: {
    selectEstablishment() {
      this.$emit("selectEstablishment", this.establishment);
    },
  },
};
</script>
