<template>
  <a-modal
    v-model="visible"
    class="modal-logout"
    title="Menú"
    :maskClosable="false"
  >
    <div class="modal-content">
      <a-icon type="question-circle"></a-icon>
      ¿Está seguro que desea cerrar sesión?
    </div>
    <template slot="footer">
      <a-button key="back" @click="$emit('cancel')">
        Cancelar
      </a-button>
      <a-button @click="$emit('confirm')" class="btn-confirm">
        Ok
      </a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "ConfirmModal",
  props: {
    visible: {
      default: false,
    },
  },
};
</script>
