import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'OrderHistory',
    component: () => import(/* webpackChunkName: "OrderHistory" */ '@/views/history/OrderHistory.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/LogIn.vue')
  },
  {
    path: '/menu',
    name: 'EstablishmentMenu',
    component: () => import(/* webpackChunkName: "EstablishmentMenu" */ '@/views/menu/EstablishmentMenu.vue')
  },
  {
    path: '/order-active',
    name: 'OrderActive',
    component: () => import(/* webpackChunkName: "OrderActive" */ '@/views/orders/OrderActive.vue')
  },
  {
    path: '/xpress',
    name: 'Xpress',
    component: () => import(/* webpackChunkName: "Xpress" */ '@/views/Xpress/Xpress.vue')
  },
  {
    path: '/xpress/:orderType/:fee',
    name: 'XpressMenu',
    component: () => import(/* webpackChunkName: "XpressMenu" */ '@/views/Xpress/Menu.vue')
  },
  {
    path: '/xpress/:user/:eid',
    name: 'XpressAddress',
    component: () => import(/* webpackChunkName: "XpressAddress" */ '@/views/Xpress/Address.vue')
  },
  {
    path: '/xpress/:fee',
    name: 'XpressEnv',
    component: () => import(/* webpackChunkName: "XpressEnv" */ '@/views/Xpress/Env.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/settings/Settings.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  
  if (authRequired && !store.getters.isLoggedIn) {
    return next('/login');
  }
  next();
});

export default router
