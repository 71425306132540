<template>
  <div class="status noselect" @click="connectEstablishment">
    <div
      :class="status ? 'establishment-status' : 'establishment-status offline'"
    >
      <p>{{ getStatusText }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Connect",
  props: {
    status: null
  },
  methods: {
    connectEstablishment() {
      this.$emit("connectEstablishment");
    },
  },
  computed: {
    getStatusText() {
      return this.status ? "DISPONIBLE" : "DESCONECTADO";
    }
  }
};
</script>
