import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Antd from "ant-design-vue";
import { parseData } from './utils/storage'

import "./plugins/BootstrapVue";

import "./styles/Base.scss";
import "ant-design-vue/dist/antd.css";

const GOOGLE_MAPS_API_KEY = "AIzaSyBR0BN9GlGVA5fy459Det9zzCp8yHJ5eJI";

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAPS_API_KEY,
    libraries: "places",
  },
  installComponents: true,
});

import Geocoder from "@pderas/vue2-geocoder";

Vue.use(Geocoder, {
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
});

Vue.use(Antd);

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
Vue.prototype.$GoogleMapsApiKey = GOOGLE_MAPS_API_KEY;

const auth = localStorage.getItem('auth');

if (auth) {
  const token = parseData(auth, 'token');
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
