const status = {
  response: {
    CANCEL: -2,
    REJECT: -1,
    NEW: 0,
    ACCEPT: 1,
    READY: 2,
    DELIVERIER_ASSIGNED: 3,
    DELIVERIER_TO_DELIVER: 4,
    ACCEPTED: 5,
  },
  receive: {
    PENDING: "PENDIENTE", // 0
    PREPARING: "EN PREPARACIÓN", // 1
    REJECTED: "RECHAZADA", // -1
    WAITING_DELIVERY: "ESPERANDO REP.", // 2
    ACCEPTED: "FINALIZADA",
    CANCEL: "CANCELADA", // -2
    REP_ON_GOING: "REP. EN CAMINO"
  },
  orderStatus: {
    "-2": "CANCELADA",
    "-1": "RECHAZADA",
    0: "PENDIENTE",
    1: "EN PREPARACIÓN",
    2: "ESPERANDO REP.",
    3: "REP. EN CAMINO",
    4: "",
    5: "FINALIZADA",
  },
  orderStatusClass: {
    "-2": "order-cancelled",
    "-1": "order-rejected",
    0: "order-pending",
    1: "order-new",
    2: "order-waiting-delivery",
    3: "order-waiting-delivery",
    4: "",
    5: "order-finalized",
  },
};

export default status;
