<template>
  <i aria-label="icon: ex" class="anticon" :style="'margin-left:' + marginLeft">
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.899414"
        y="0.452271"
        width="21"
        height="21"
        rx="4"
        fill="white"
      />
      <path
        d="M10.0078 11.4689H6.63281V13.754H10.5938V15.1661H4.875V6.63489H10.582V8.05872H6.63281V10.0919H10.0078V11.4689ZM14.6777 9.57629L16.2773 6.63489H18.2988L15.8145 10.8654L18.3633 15.1661H16.3184L14.6777 12.1779L13.0371 15.1661H10.9922L13.541 10.8654L11.0566 6.63489H13.0781L14.6777 9.57629Z"
        fill="#FF3B69"
      />
    </svg>
  </i>
</template>
<script>
export default {
  props: {
    marginLeft: {
      type: String,
      default: "-3px",
    },
  },
};
</script>
