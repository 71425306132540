<template>
  <a-modal
    v-model="visible"
    :forceRender="true"
    title="Disponibilidad de productos"
    class="order-adjust product-edit-modal"
  >
    <div class="modal-content" v-if="menu">
      <a-row type="flex">
        Los siguientes productos del menú {{ menuName }} se encuentran deshabilitados indefinidamente.
        <a-divider />
        <br />
        <div style="display: grid;">
          <span v-for="product in menu.products" :key="product.id">
            → {{ product.name }}
          </span>
          <br />
        </div>
      </a-row>
    </div>

    <template slot="footer">
      <a-button key="back" @click="$emit('cancel')"> Cerrar </a-button>
      <a-button @click="onOk" class="btn-confirm">
        Ir a disponibilidad
      </a-button>
    </template>
  </a-modal>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  name: "ProductAvailabilitDetails",
  props: {
    menu: null,
    visible: {
      default: false,
    },
  },
  methods: {
    onOk() {
      this.$emit("onOk");
    },
  },
  computed: {
    menuName() {
      return this.menu.name;
    }
  }
};
</script>
