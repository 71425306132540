<template>
  <div id="app">
    <log-in v-if="isLogIn" v-on:loggedIn="loggedIn" />
    <a-layout id="components-layout-fixed-sider" v-else>
      <a-layout-sider
        :trigger="null"
        collapsible
        @mouseover="collapsed = false"
        @mouseleave="collapsed = true"
        :style="{ position: 'fixed' }"
        :class="collapsed ? 'sider-not-collapsed' : 'sider-collapsed'"
      >
        <div>
          <img
            class="img-logo"
            src="@/assets/icon-white.svg"
            width="200px"
            alt="Mr Foody"
          />
        </div>
        <a-menu theme="dark" mode="inline">
          <a-menu-item
            key="2"
            :class="
              selectedOption === '/order-active' ? 'selected' : 'not-selected'
            "
          >
            <router-link to="/order-active">
              <a-icon type="ordered-list" />
              <span class="element-menu">Órdenes</span></router-link
            >
          </a-menu-item>
          <a-menu-item
            key="3"
            :class="selectedOption === '/menu' ? 'selected' : 'not-selected'"
          >
            <router-link to="/menu">
              <a-icon type="tags" />
              <span class="element-menu">Disponibilidad</span></router-link
            >
          </a-menu-item>
          <a-menu-item
            key="4"
            class="ex-icon"
            :class="selectedOption === '/xpress' ? 'selected' : 'not-selected'"
          >
            <router-link to="/xpress">
              <ExIcon />
              <span class="element-menu">Xpress</span></router-link
            >
          </a-menu-item>
          <a-menu-item
            key="1"
            :class="selectedOption === '/' ? 'selected' : 'not-selected'"
          >
            <router-link to="/">
              <a-icon type="ordered-list" />
              <span class="element-menu">Historial</span></router-link
            >
          </a-menu-item>
          <a-menu-item
            key="5"
            :class="
              selectedOption === '/settings' ? 'selected' : 'not-selected'
            "
          >
            <router-link to="/settings">
              <a-icon type="setting" theme="filled" />
              <span class="element-menu">Configuración</span></router-link
            >
          </a-menu-item>
          <a-menu-item class="not-selected">
            <div @click="visible = true">
              <a-icon type="logout" />
              <span class="element-menu">Cerrar sesión</span>
            </div>
          </a-menu-item>
        </a-menu>
        <div
          :class="`element-menu-establishment-name ${
            hasSeveralEstablishments ? 'element-select-establishment' : ''
          }`"
          @click="changeEstablishment"
        >
          <span class="not-selected"
            >{{ getEstablishmentName }}
            <span class="switch-establishment"
              ><template v-if="hasSeveralEstablishments"
                ><br />Cambiar Establecimiento</template
              ></span
            >
          </span>
        </div>
        <Connect
          :status="status"
          v-on:connectEstablishment="connectEstablishment"
        />
      </a-layout-sider>
      <a-layout style="min-height: 100vh">
        <a-layout-content
          :class="collapsed ? '' : 'collapsed'"
          :style="{ margin: '24px 16px 0' }"
        >
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-modal
      v-model="isNewOrder"
      :forceRender="true"
      :footer="null"
      class="order-adjust product-edit-modal new-order-modal"
    >
      <NewOrder
        v-if="isNewOrder"
        v-on:receiveOrder="receiveOrder"
        v-on:ignoreOrder="ignoreOrder"
      />
    </a-modal>
    <ConfirmModal
      :visible="isLogOutVisible"
      @cancel="visible = false"
      @confirm="logout"
    />
    <SoundSettings
      :visible="soundSettingsVisible"
      @confirm="acceptSoundSettings"
    />
    <SelectEstablishment
      :visible="selectEstablishmentVisible"
      :establishments="establishments"
      :selectedElement="selectedEstablishmentIndex"
      @selectEstablishment="redirectToSelectedEstablishment"
    />
    <ProductAvailabilityDetails
      :visible="productAvailabilityVisible"
      :menu="unavailableMenuProducts"
      @onOk="onOkProductAvailabilityDetails"
      @cancel="onCancelProductAvailabilityDetails"
    />
  </div>
</template>

<script>
import Connect from "./components/app/Connect";
import LogIn from "./views/login/LogIn";
import ConfirmModal from "./components/login/ConfirmModal";
import ExIcon from "./icons/Ex.vue";
import NewOrder from "@/components/orders/NewOrder";
import SoundSettings from "@/components/modal/SoundSettings";

import statusOrder from "./utils/orderStatus";
import SelectEstablishment from "./components/login/SelectEstablishment.vue";
import ProductAvailabilityDetails from './components/modal/ProductAvailabilityDetails.vue';

import moment from "moment";
moment.locale("es");

const audio1 = new Audio(require("@/assets/new_order.mp3"));
const audio0 = new Audio(require("@/assets/new_order_2.mp3"));

export default {
  data() {
    return {
      collapsed: true,
      visible: false,
      selectedOption: "/",
      serverTimeDiff: 0,
      serverTimeUpdated: false,
      status: false,
      newOrderAvailable: false,
      elapsedTime: null,
      mainInterval: null,
      soundSettingsVisible: false,
      selectEstablishmentVisible: false,
      productAvailabilityVisible: false,
      unavailableMenuProducts: null,
      isEstablishmentChanged: false,
    };
  },
  computed: {
    hasSeveralEstablishments() {
      return this.establishments.length > 1;
    },
    establishments() {
      return this.$store.getters.getEstablishments;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    isLogIn() {
      return this.$route.name == "login";
    },
    isNewOrder() {
      return this.newOrderAvailable;
    },
    isLogOutVisible() {
      return !this.$store.getters.isNewOrder && this.visible;
    },
    audio() {
      const selectedAudio = this.$store.state.appSound;
      if (selectedAudio == 1) {
        return audio1;
      }
      if (selectedAudio == 0) {
        return audio0;
      }
      return false;
    },
    getEstablishmentName() {
      return this.$store.getters.getEstablishmentName;
    },
    selectedEstablishmentIndex() {
      return this.$store.getters.getSelectedEstablishmentIndex;
    },
  },
  components: {
    Connect,
    LogIn,
    ConfirmModal,
    ExIcon,
    NewOrder,
    SoundSettings,
    SelectEstablishment,
    ProductAvailabilityDetails
  },
  mounted: async function () {
    const darkMode = this.$store.state.darkMode;

    if (
      darkMode ||
      (window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      this.$store.commit("set_darkMode", true);
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }

    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        throw err;
      });
    });
    if (this.isLoggedIn) {
      this.soundSettingsVisible = true;
      this.$router.push("/order-active");
      this.updateEstablishmentMenu();
    }
  },
  destroyed() {
    clearInterval(this.mainInterval);
  },
  methods: {
    changeEstablishment() {
      if (this.hasSeveralEstablishments) {
        this.selectEstablishmentVisible = true;
      }
    },
    loggedIn() {
      this.updateEstablishmentMenu();
      this.soundSettingsVisible = true;
    },
    logout() {
      this.visible = false;
      clearInterval(this.mainInterval);
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    getElapsed(now, updated) {
      return now.diff(updated);
    },
    setElapsed(elapsed) {
      return moment(elapsed).utc().format("HH:mm:ss");
    },
    async updateEstablishmentMenu() {
      let resp = await this.$store.dispatch("get", {
        location: [
          "establishment-menu-by-establishment",
          this.$store.getters.establishmentId,
        ].join("/"),
      });

      if (resp) {
        this.$store.commit("set_menu", resp.establishment.menus);
      } else {
        this.logout();
      }
    },
    receiveOrder() {
      this.newOrderAvailable = false;
      if (this.$route.name !== "OrderActive") {
        this.$router.push("/order-active").catch(() => {});
      }
    },
    ignoreOrder() {
      this.newOrderAvailable = false;
    },
    updateSelected(to) {
      const path = to.path;
      this.selectedOption =
        path.split("/").length > 2
          ? path.substring(0, path.indexOf("/", path.indexOf("/") + 1))
          : path;
    },
    acceptSoundSettings() {
      this.soundSettingsVisible = false;
      this.getUnavailableProducts();
    },
    async getUnavailableProducts() {
      const menuId = this.$store.getters.establishmentMenu[this.$store.getters.getSelectedMenu].id;

      let resp = await this.$store.dispatch("get", {
        location: [
          "products/unavailable",
          this.$store.getters.establishmentId,
          menuId
        ].join("/"),
      });

      if (resp.menu.products.length > 0) {
        this.unavailableMenuProducts = resp.menu;
        this.productAvailabilityVisible = true;
      } else {
        this.startClient();
      }
    },
    onCancelProductAvailabilityDetails() {
      this.productAvailabilityVisible = false;
      this.startClient();
    },
    onOkProductAvailabilityDetails() {
      this.productAvailabilityVisible = false;
      this.$router.push("/menu").catch(() => {});
      this.startClient();
    },
    startClient() {
      // Initial call, retrieve data
      this.retrieveData(this.isEstablishmentChanged);
      // Make calls to retrieve orders and establishment status
      if (!this.isEstablishmentChanged) {
        this.mainInterval = setInterval(() => {
          this.retrieveData();
        }, 1000);
      }
    },
    async retrieveData(isChangeEstablishment = false) {
      // Check if 30 secons passed
      const now = moment();

      const currentTime = moment(now - this.serverTimeDiff);

      let activeOrders = this.$store.getters.getActiveOrders;
      if (
        this.elapsedTime === null ||
        (currentTime - this.elapsedTime) / 1000 > 30 || isChangeEstablishment
      ) {
        // Update establishment menu
        this.updateEstablishmentMenu();

        let resp = await this.$store.dispatch("get", {
          location: [
            "establishment/get-all-orders-electron" /*"orders/get-active-orders"*/,
            this.$store.getters.establishmentId,
          ].join("/"),
        });

        let orders = resp.orders;
        const serverTimeUpdated = resp.server_time;
        if (!this.serverTimeUpdated) {
          this.serverTimeDiff = now.diff(moment(serverTimeUpdated));
          this.serverTimeUpdated = true;
        }
        //this.$store.commit("set_server_time", serverTimeUpdated);

        if (
          (activeOrders === null && orders.length > 0) ||
          (activeOrders !== null && orders.length > activeOrders.length)
        ) {
          // Check new orders
          let orderIntersection = activeOrders
            ? orders.filter((o) => {
                return !activeOrders.some((ao) => ao.id === o.id);
              })
            : orders;

          // UNCOMMENT IF XPRESS ARE NOT GETTING NOTIFIED
          // Then for new orders get only the orders that are not xpress
          // check if the response order is greater than the current number of orders
          // if that's the case, then add true to that value so we can show the new order view
          // let normalOrder = orderIntersection.filter(
          //  (o) => typeof o.type === "undefined"
          //);

          this.newOrderAvailable =
            orderIntersection.filter((o) => o.status === 0).length > 0;
        }

        activeOrders = orders;

        // Retrieve establishment suppport

        resp = await this.$store.dispatch("get", {
          location: "support-profile",
        });
        let currentMonitor = resp.monitors.length > 0 ? resp.monitors[0] : "";
        if (resp.profile != null) {
          currentMonitor = resp.profile;
        }

        this.$store.commit("support", currentMonitor);

        // Retrieve establishment status
        resp = await this.$store.dispatch("get", {
          location: [
            "is-establishment-connected",
            this.$store.getters.establishmentId,
          ].join("/"),
        });

        this.status = resp.connected;
        this.$store.commit("establishment_status", resp.connected);
        // Update time of last call
        this.elapsedTime = currentTime;
      }

      // Update time for all current open orders
      activeOrders.forEach(
        function (o) {
          const updated = moment(o.updated_at);
          let elapsed = currentTime.diff(updated);
          if (o.status === statusOrder.response.ACCEPT && o.cooking_time) {
            const totalTime = moment(o.cooking_time.created_at).add(
              o.cooking_time.estimated_cooking_time || o.cooking_time,
              "minutes"
            );

            const e = moment(totalTime - currentTime);

            if (e < 1000 || o.cooking_time_ended) {
              o.cooking_time_ended = true;
            }
            elapsed = new Date(Math.abs(e));
          }
          o.elapsed_time = this.setElapsed(elapsed);
        }.bind(this)
      );

      this.$store.commit("set_orders_active", activeOrders);

      // Update current time
      this.$store.commit("set_current_time", currentTime);
    },
    async connectEstablishment() {
      const resp = await this.$store.dispatch("post", {
        route: [
          "establishment/connect",
          this.$store.getters.establishmentId,
        ].join("/"),
        data: null,
      });

      if (resp.ok) {
        this.$store.commit("establishment_status", resp.updatedStatus);
        this.status = resp.updatedStatus;
      }
    },
    async redirectToSelectedEstablishment(establishmentIndex) {
      this.$store.commit("set_establishment", {
        establishment: this.establishments[establishmentIndex],
        index: establishmentIndex,
      });
      let resp = await this.$store.dispatch("get", {
        location: [
          "establishment-menu-by-establishment",
          this.$store.getters.establishmentId,
        ].join("/"),
      });
      this.$store.commit("set_menu", resp.establishment.menus);
      this.selectEstablishmentVisible = false;
      
      this.$store.commit("set_selected_menu", 0);
      this.isEstablishmentChanged = true;
      this.getUnavailableProducts();
      //this.retrieveData(true);
    },
  },
  watch: {
    $route(to, from) {
      this.updateSelected(to);

      let xpressOrderOut = new RegExp(
        "^/xpress/((?:[^/]+?))/((?:[^/]+?))(?:/(?=$))?$"
      );
      if (
        xpressOrderOut.test(from.path) &&
        this.$store.getters.isSavedXpressOrder
      ) {
        this.$message.info("Orden en curso guardada", 5);
      }
    },
    newOrderAvailable: {
      handler() {
        this.$store.commit("set_new_order", this.newOrderAvailable);
      },
    },
    isNewOrder(newValue) {
      if (newValue) {
        if (!this.audio) {
          return;
        }
        this.audio.loop = true;
        this.audio.play();
      } else {
        if (!this.audio) {
          return;
        }
        this.audio.loop = false;
      }
    },
  },
};
</script>
