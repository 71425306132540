<template>
  <a-modal
    v-model="visible"
    class="modal-logout"
    title="Alarma"
    :maskClosable="false"
  >
    <div class="modal-content">
      <a-row type="flex">
        <a-col :span="2"><a-icon type="info-circle"></a-icon></a-col>
        <a-col :span="22"
          ><span
            >La alarma de orden nueva sonará cuando el establecimiento reciba
            una nueva orden. Para silenciar las alarmas, puede navegar a
            <strong>Configuración</strong> y deshabilitarla.</span
          ></a-col
        >
      </a-row>
    </div>
    <template slot="footer">
      <a-button @click="$emit('confirm')" class="btn-confirm"> Ok </a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "Alarm",
  props: {
    visible: {
      default: false,
    },
  },
};
</script>
